import { Result, Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { HELP_CENTER_URL } from '../../constants'

// the fallback UI which renders when an error is caught
export const ErrorFallBack = ({ error }) => {
  return (
    <Wrapper>
      <Result
        status='500'
        title='An Error Occured'
        subTitle={
          error.message ||
          'Sorry, something went wrong. Please refresh the page or try again later.'
        }
        extra={
          <>
            <Button type='primary' onClick={() => window.location.reload(false)}>
              <Link to='/'>Go Home</Link>
            </Button>
            <Button type='secondary' onClick={() => window.location.reload(false)}>
              Refresh
            </Button>
          </>
        }
      />
      <a href={HELP_CENTER_URL} target='_blank' rel='noreferrer'>
        <Button type='link'>Need Help?</Button>
      </a>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding: 20px;
  margin: auto;
`
