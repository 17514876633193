import React, { useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useQuery } from 'react-query'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorFallBack } from './components/general/ErrorFallBack'
import LoadingHeart from './components/general/LoadingHeart'
import { Layout } from './components/screens/dashboard/Layout'
import { GlobalContext } from './contexts/GlobalContext'
import { UserContext } from './contexts/UserContext'

export const Base = () => {
  const { idmToken } = useContext(GlobalContext)
  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData, status: userStatus } = useQuery('user', fetchCurrentUser)
  const location = useLocation()

  if (!idmToken && location.pathname !== '/login') {
    return <Navigate to='login' />
  }

  // const TestErrorComponent = () => {
  //   throw new Error('This is a test error!')
  //   return <div>Will not be reached</div>
  // }

  return (
    <Wrapper>
      <ErrorBoundary FallbackComponent={ErrorFallBack} showDialog={true}>
        {/* <TestErrorComponent /> */}
        {idmToken && userStatus === 'success' ? (
          <Layout userData={userData}>
            <Outlet />
          </Layout>
        ) : (
          (userStatus === 'loading' || !userData) && (
            <div className='loading'>
              <LoadingHeart />
            </div>
          )
        )}
      </ErrorBoundary>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .loading {
    display: flex;
    background: #f4f4f8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`
