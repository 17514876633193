import { InboxOutlined } from '@ant-design/icons'
import { Button, Drawer, Dropdown } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { NotificationMenu } from './NotificationMenu'
import creatorcoLogo from '../../assets/images/primary-logo.svg'
import { HELP_CENTER_URL } from '../../constants'
import { UserContext } from '../../contexts/UserContext'
import { routes } from '../../routes'
import { Avatar } from '../general/Avatar'
import { Hamburger } from '../general/Hamburger'

const Header = () => {
  const { idmToken, fetchCurrentUser, fetchNotifications } = useContext(UserContext)
  const [notificationsOpen, setNotificationsOpen] = useState(false)

  const { data: userData, status } = useQuery('user', fetchCurrentUser)
  const { data: notifications } = useQuery('notifications', fetchNotifications)

  // user.com page view events
  useEffect(() => {
    if (status === 'success' && userData) {
      window.UE.pageHit({
        apiKey: 'EsRpsv',
        custom_id: userData.id, // optional - the external ID of the user (in our system, can use user.com API to find our registered users by this ID)
        name: `${userData.firstName} ${userData.lastName}`,
        email: userData.email,
        registered_date: userData.registered,
        has_social_connected: userData.socialProfiles?.length,
        total_opt_ins: userData.optIns?.length || 0,
      })
    }
  }, [status, userData])

  const DashboardMenuItems = routes.map((route, i) => ({
    key: i.toString(),
    label: (
      <MenuLink to={route.path} className={route.className}>
        {route.icon}
        {route.label}
      </MenuLink>
    ),
  }))

  return (
    <Wrapper>
      <div className='inner'>
        <a className='logo' href='https://creator.co'>
          <img src={creatorcoLogo} alt='creatorco' />
        </a>

        <div className='buttons'>
          <div className='nav'>
            <Link to='/collaboration-hub'>Collaboration Hub</Link>
            <a
              className='link'
              href='https://creator.co/blog'
              target='_blank'
              rel='noopener noreferrer'>
              Blog
            </a>
            <a
              className='link'
              href='https://creator.co/creator-contact'
              target='_blank'
              rel='noopener noreferrer'>
              Contact
            </a>
            <a className='link' href={HELP_CENTER_URL} target='_blank' rel='noopener noreferrer'>
              Help Center
            </a>

            {idmToken && userData ? (
              <>
                <div className='user'>
                  <Dropdown
                    getPopupContainer={trigger => trigger.parentNode}
                    menu={{ items: DashboardMenuItems }}
                    trigger={['click']}>
                    <div className='dropdown'>
                      <Avatar
                        size={30}
                        initials={`${userData?.firstName
                          .charAt(0)
                          .toUpperCase()} ${userData?.lastName.charAt(0).toUpperCase()}`}
                      />
                      <span className='name'>{`${userData.firstName} ${userData.lastName}`}</span>
                    </div>
                  </Dropdown>
                </div>
              </>
            ) : (
              <div className='ctas'>
                <Link to='/login' className='cta'>
                  <Button type='primary'>Log In</Button>
                </Link>
                <Link to='/signup' className='cta'>
                  <Button type='secondary'>Sign Up</Button>
                </Link>
              </div>
            )}
          </div>

          <Hamburger />

          {idmToken && userData && (
            <div
              className='notifications-btn'
              onClick={() => setNotificationsOpen(true)}
              onKeyDown={() => setNotificationsOpen(true)}
              role='button'
              tabIndex={0}>
              <InboxOutlined />
              {!!notifications?.length && <span className='notification-badge' />}
            </div>
          )}

          <Drawer
            title={`Your Notifications (${notifications?.length ? notifications.length.toLocaleString() : 0})`}
            open={notificationsOpen}
            onClose={() => setNotificationsOpen(false)}>
            <NotificationMenu
              notifications={notifications}
              setNotificationsOpen={setNotificationsOpen}
            />
          </Drawer>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  .inner {
    margin: auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 50px;
    .logo {
      img {
        height: 20px;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .nav {
      font-size: 0.9rem;
      display: none;
      .link {
        color: ${props => props.theme.crcoMidnight};
        margin-left: 20px;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        &:hover {
          color: ${props => props.theme.crcoTechBlue};
        }
        img {
          height: 20px;
        }
      }
      .user {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-left: 20px;
        .dropdown {
          display: flex;
          cursor: pointer;
        }
        .name {
          margin-left: 10px;
          display: none;
          &::after {
            content: '';
            width: 8px;
            height: 8px;
            border-style: solid;
            border-color: #080830;
            border-width: 2px 2px 0 0;
            margin: 0 0 5px 10px;
            transform: rotate(135deg);
            transition: 0.2s ease-in-out;
          }
        }
      }
      .ctas {
        display: flex;
      }
    }

    .notifications-btn {
      height: 30px;
      width: 30px;
      display: grid;
      place-content: center;
      font-size: 1.2rem;
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        border-color: ${props => props.theme.crcoTechBlue};
        color: ${props => props.theme.crcoTechBlue};
      }
      .notification-badge {
        width: 12px;
        height: 12px;
        background-color: ${props => props.theme.crcoCoral};
        border-radius: 50%;
        position: absolute;
        top: -5px;
        right: -5px;
        border: 2px solid white;
      }
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .inner {
      .logo img {
        height: 22px;
      }
      .nav {
        display: flex;
        align-items: center;
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    .inner {
      padding: 0 40px;
      .logo img {
        height: 27px;
      }
      .nav {
        font-size: 1rem;
        .link {
          margin-left: 20px;
        }
        .user {
          border-left: 1px solid #e6e6e6;
          border-right: 1px solid #e6e6e6;
          padding: 0 20px;
          .name {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
`

const Link = styled(NavLink)`
  color: ${props => props.theme.crcoMidnight};
  margin-left: 20px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  &.active {
    color: ${props => props.theme.crcoTechBlue};
    border-bottom: 2px solid ${props => props.theme.crcoTechBlue};
  }
  &.cta {
    border: 0;
  }
  &:hover {
    color: ${props => props.theme.crcoTechBlue};
  }
`

const MenuLink = styled(NavLink)`
  .anticon {
    margin-right: 10px;
  }
  &.active {
    color: ${props => props.theme.crcoTechBlue};
  }
  &.logout {
    color: #ff0000;
    &:hover {
      color: #ff0000;
    }
  }
`

export default Header
