import axios from 'axios'
import LogRocket from 'logrocket'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import Cookies from 'universal-cookie'

import { FeatureContext } from './FeatureContext'
import { GlobalContext } from './GlobalContext'
import { API_URL } from '../constants'

export const UserContext = createContext(null)

export const UserContextProvider = props => {
  const { idm, idmToken, setIdmToken } = useContext(GlobalContext)
  const { getFeatureFlagsString, clearCookies } = useContext(FeatureContext)
  const [token, setToken] = useState() // TODO: deprecated for idmToken, replace references
  const [phylloError, setPhylloError] = useState(false)
  const queryClient = new useQueryClient()
  const cookies = new Cookies()

  const [lgLoaded, setLgLoaded] = useState(!!window.logRocketInitialized)

  useEffect(() => {
    if (idmToken) {
      setToken(idmToken)
      setupAxiosInterceptor(idmToken)
    } else {
      setToken(null)
      setupAxiosInterceptor(null)
      queryClient.removeQueries()
    }
  }, [idmToken])

  const setupAxiosInterceptor = idmToken => {
    axios.interceptors.request.use(
      function (config) {
        if (!config.headers.Authorization)
          if (idmToken) {
            config.headers.Authorization = `Bearer ${idmToken}`
          } else {
            config.headers.Authorization = undefined
          }
        config.headers['X-Experimental-Feature-Flag'] = getFeatureFlagsString()

        return config
      },
      function (error) {
        return Promise.reject(error)
      }
    )
  }

  const logoutHandler = () => {
    if (idm) {
      idm.authenticator.logout()
    } else {
      clearCookies()
      window.location.replace('/login')
    }
    setIdmToken(null)
  }

  const fetchCurrentUser = async () => {
    // ref used for zendesk SSO
    const zendeskRedirectTo = localStorage.getItem('zendesk-ref')
    const { data } = await axios
      .get(`${API_URL}/me`, {
        params: { zendeskRedirectTo },
        headers: {
          Authorization: `Bearer ${idmToken || cookies.get('idm_token_v1') || (await idm.session.getToken())}`,
          'X-Experimental-Feature-Flag': getFeatureFlagsString(),
        },
      })
      .catch(err => {
        if (err?.response?.status === 401 && idmToken) {
          logoutHandler()
        }
        return null
      })

    // Make sure that the experimental feature flag matches the user's feature flag set in metadata (which is the source of truth)
    if (
      data.extraData.experimentalFeatures &&
      data.extraData.experimentalFeatures !== cookies.get('featureFlag')
    ) {
      window.location.replace('/logout')
    }

    if (data.zendeskEndpoint) {
      localStorage.removeItem('zendesk-ref')
      window.location.href = data.zendeskEndpoint
    }

    // Record creator user sessions on production with LogRocket
    if (!lgLoaded && data.role !== 'administrator' && process.env.NODE_ENV === 'production') {
      LogRocket.init('zbp1lp/creator-co', {
        // Sanitize the request body to avoid sending sensitive data to LogRocket (such as passwords)
        requestSanitizer: request => {
          if (request.url.toLowerCase().indexOf('/login') !== -1) request.body.password = null
          return request
        },
      })

      // Identify the user
      LogRocket.identify(data.id, {
        name: `${data.firstName} ${data.lastName}`,
        email: data.email,
        // User traits (can be used to search sessions)
        type: data.role,
      })

      // Prevent LogRocket from initializing multiple times
      setLgLoaded(true)
    }

    return data
  }

  const updateCurrentUser = async data => {
    try {
      const res = await axios.post(`${API_URL}/me`, data)
      queryClient.invalidateQueries('user')
      return res.data
    } catch (err) {
      return { error: 'Oops, something went wrong.' }
    }
  }

  const optInCreator = async (campaignId, variables, selectedProduct, channels) => {
    try {
      const res = await axios.post(`${API_URL}/opt-in-creator`, {
        campaignId,
        variables,
        selectedProduct,
        channels,
        inviteCode: localStorage.getItem('inviteCode'),
      })
      return res.data
    } catch (err) {
      return { error: err.response.data }
    }
  }

  // HANDLING USER DATA SPECIFIC TO CREATORS
  const updateCreatorProfile = async data => {
    try {
      const res = await axios.post(`${API_URL}/me/creator-settings`, data)
      queryClient.invalidateQueries('user')
      return res.data
    } catch (err) {
      return { error: 'Oops, something went wrong.' }
    }
  }

  // HANDLING CREATOR PROFILE SETTINGS
  // used in CreatorProfileSettings.js - updates user/user.creatorProfile/creatorProfile.profileSettings depending on values passed in
  const updateProfileSettings = async data => {
    try {
      const res = await axios.put(`${API_URL}/creator-space/settings`, data)
      await queryClient.invalidateQueries('user')
      return res.data
    } catch (err) {
      return { error: 'Oops, something went wrong.' }
    }
  }

  // HANDLING SOCIAL ACCOUNT CONNECTED
  const updateSocialProfile = async accountId => {
    try {
      const res = await axios.post(`${API_URL}/me/social-connect/${accountId}`)
      await queryClient.invalidateQueries('user')
      return res
    } catch (err) {
      return { error: 'Oops, something went wrong.' }
    }
  }

  const deleteSocialProfile = async platform => {
    try {
      const res = await axios.delete(`${API_URL}/me/social-connect/${platform}`)
      await queryClient.invalidateQueries('user')
      return res
    } catch (err) {
      return { error: 'Oops, something went wrong.' }
    }
  }

  const closeAccount = async (userId, data) => {
    try {
      const res = await axios.delete(`${API_URL}/creator/${userId}/account`, {
        data,
      })
      logoutHandler()
      return res
    } catch (err) {
      return { error: err.response?.data?.err || 'An unknown error occurred.' }
    }
  }

  const fetchInvites = async () => {
    const { data } = await axios.get(`${API_URL}/me/invites`)
    return data
  }

  const fetchNotifications = async () => {
    if (token) {
      const { data } = await axios.get(`${API_URL}/me/notifications`)
      return data
    }
  }

  const userContextValues = {
    idmToken,
    phylloError,
    setPhylloError,
    logoutHandler,
    fetchCurrentUser,
    updateCurrentUser,
    updateCreatorProfile,
    updateProfileSettings,
    updateSocialProfile,
    deleteSocialProfile,
    optInCreator,
    closeAccount,
    fetchInvites,
    fetchNotifications,
  }

  return <UserContext.Provider value={userContextValues}>{props.children}</UserContext.Provider>
}
