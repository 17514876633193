import React, { createContext } from 'react'
import Cookies from 'universal-cookie'

export const FeatureContext = createContext(null)

export const FeatureContextProvider = props => {
  const cookies = new Cookies()

  const hasFeatureFlag = flag => {
    const flags = getFeatureFlags()
    return flag && flags.includes(flag?.toLowerCase())
  }

  const getFeatureFlags = () => {
    let featureFlag = cookies.get('featureFlag')
    if (featureFlag === 'undefined') clearCookies()
    const featureFlagArray = featureFlag?.split(',').map(ff => ff.toLowerCase())
    return featureFlagArray || []
  }

  const getFeatureFlagsString = () => {
    return getFeatureFlags().join(',')
  }

  const clearCookies = () => {
    cookies.remove('featureFlag')
    cookies.remove('idm_token_v1')
  }

  const contextValues = {
    hasFeatureFlag,
    getFeatureFlags,
    getFeatureFlagsString,
    clearCookies,
  }

  return <FeatureContext.Provider value={contextValues}>{props.children}</FeatureContext.Provider>
}
